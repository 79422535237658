import { useState, useEffect } from "react";

import verifycode from "../../../assets/welcomepage/otp.png";

import { Link } from "react-router-dom";
export default function GuestOtpEmail() {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
  };

  const [showTimer, setShowTimer] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(10);
  useEffect(() => {
    let timerId;

    if (showTimer && secondsLeft > 0) {
      timerId = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (showTimer) {
      setShowTimer(false);
      setSecondsLeft(10);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [showTimer, secondsLeft]);

  function handleClick() {
    setShowTimer(true);
  }
  return (
    <>
      <div className="--guest-layout  h-screen bg-banner bg-11 ">
        <div className="content-started mb-3   bg-white lg:w-[41%] lg:mx-auto rounded-md relative lg:top-8 lg:h-[92vh] fade-in-left">
          <div className="rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-1   bg-white">
            <h2 className="text-black text-lg font-semibold  mb-5 lg:mt-[6rem] mont-serif relative lg:bottom-11 text-center">
              VERIFY CODE
            </h2>
            <div className="container mx-auto">
              <img
                className="rounded-lg  mx-auto object-cover lg:h-[7rem] w-[auto]"
                src={verifycode}
                alt=""
              />
            </div>
            {/* email input  */}
            <div className="flex justify-center items-center lg:mt-[4rem] lg:mb-16">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="number"
                  className="text-black w-12 h-12 mx-4 text-4xl text-center border border-gray-300   bg-transparent rounded-lg focus:outline-none focus:border-indigo-500"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                />
              ))}
            </div>

            {/* email input end  */}

            <div
              className="forgot-password flex flex-col gap-3 justify-center mt-6"
              style={{ alignItems: "center" }}
            >
              {!showTimer && (
                <button
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                  className="mont-serif xs:w-[15rem] text-black button-1 border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[48%]"
                  onClick={handleClick}
                >
                  get otp 
                </button>
              )}
              {showTimer && (
                <div className="flex gap-2  lg:w-[48%] justify-center border rounded bg-transparent lg:p-[2px] focus:outline-none text-lg drop-shadow-md shadow-lg">
                  <span className="text-black xs:w-[15rem]">
                    {" "}
                    send again in
                  </span>{" "}
                  <p className="text-black xs:w-[15rem]">{` ${secondsLeft}s`}</p>
                </div>
              )}
              <Link to="/guest-regestration">
             
                <button
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                  className=" xs:w-[15rem] mont-serif lg:w-[15rem] text-black  bg-white border-0 lg:p-[2px] focus:outline-none hover:bg-teal-100 rounded text-lg drop-shadow-md shadow-lg"
                >
                  next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
