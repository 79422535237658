import React, { useState } from "react";
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import Sidebar from '../Sidebar';
import Footer from  "../Footer"
import {  useNavigate } from "react-router-dom";
import SideMobile from "../SideMobile";
import Logout from "../Home/Logout.png"
import { RxCross1 } from "react-icons/rx";
import Searchtab from "../Searchtab";
export default function Privacystatic() {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };
  return (
  <>
  {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

<div className="sm:hidden lg:block">
    <Sidebar toggleModal={toggleModal}/>
    </div>
<div className="lg:hidden sm:block">
<SideMobile/>
</div>
    <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
    <header className="xs:hidden lg:block header bg-white shadow py-4 px-4 ">
          <Searchtab />
        </header>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="    lg:p-10 bg-about text-center">
            <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125]	xs:p-[22px] xs:mt-[7px] ">
            Privacy Policy
            </h1>
          </div>

          {/* content static text */}
          <div   className="text-gray-600 ">
            <div   className="lg:container px-5 py-8 mx-auto">
              <div   className=" lg:w-[89%] mx-auto text-center ">
              
                <p   className=" text-lg w-[80%] mx-auto mont-serif xxl:text-[16px]">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut repellendus quam debitis, sapiente, aperiam deleniti quos quod eligendi voluptatum, dolorum facilis maxime distinctio nihil. Ex tempore dolore praesentium numquam adipisci sequi magni libero tenetur tempora sed! Nihil a tempora ab harum sed laborum? Ea cupiditate laborum reiciendis earum dolor natus velit facere doloribus molestias ipsa itaque repudiandae, voluptatibus dignissimos accusamus odit. Sed sunt quos quibusdam possimus incidunt aspernatur! Ab expedita rem facere nisi cumque error excepturi iste amet, cupiditate autem perferendis hic placeat similique numquam temporibus quisquam eligendi sapiente, quidem delectus, ad in reprehenderit. Nulla id repellat aliquid architecto excepturi vitae nam voluptate doloremque obcaecati necessitatibus, deserunt similique adipisci hic, deleniti illum! Facilis veniam odio, autem aliquam itaque aut pariatur. Ea quos reiciendis nisi nulla at quisquam excepturi molestias ad! Explicabo vel sit adipisci delectus temporibus molestiae natus cum soluta dignissimos inventore harum aut tempora ipsam assumenda voluptate fuga iure, fugit suscipit officia ullam maiores eos minima odio. Veritatis quia voluptates voluptate sint, id optio quam voluptatum, consequuntur fuga sunt fugiat omnis repudiandae distinctio reprehenderit architecto recusandae ad asperiores? Voluptatibus, consequatur. Cum corrupti expedita mollitia, quas illum distinctio fuga modi cupiditate laudantium pariatur, qui 
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
  </>
  )
}
