import React, { useState ,useEffect} from "react";

import img from "../Leaguelead/glass.png"
// import ".../"
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Leagueleadtable from "./Leagueleadtable";
import Receivedtabe from "./Receivedtabe";
import Sidebar from "../../Sidebar";
import { RxCross1 } from "react-icons/rx";
import {  useNavigate } from "react-router-dom";
import Logout from "../../Home/Logout.png";

import SideMobile from "../../SideMobile";
import Footer from "../../Footer";

function Leagueleads() {
  
  const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
      setActiveTab(index);
    };

    const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);
  return (
   <>
  {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
<div className="sm:hidden lg:block">
    <Sidebar toggleModal={toggleModal}/>
    </div>
<div className="lg:hidden sm:block">
<SideMobile/>
</div>

      <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header   className="xs:hidden lg:block header bg-white shadow py-4 px-4">
          <div   className="header-content flex items-center flex-row">
            <form action="#"   className="lg:ml-[16rem]">
              <div   className="hidden md:flex relative lg:w-[136vh]">
                <div   className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                      className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <input
                  id="search"
                  type="text"
                  name="search"
                    className=" mont-serif text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg  w-full h-10 focus:outline-none "
                  placeholder="Search by company member , company or chapter"
                />
              </div>
              <div   className="flex md:hidden">
                <a
                  href="#/"
                    className="flex items-center justify-center h-10 w-10 border-transparent"
                >
                  <svg
                      className="h-6 w-6 text-gray-500"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </a>
              </div>
            </form>
            <div
                className="flex justify-center mx-auto m-auto lg:gap-[1rem]"
              style={{ alignItems: "center" }}
            >
              <AiOutlineMail />
              <AiTwotoneBell />
              <FaUserAlt />
            </div>
          </div>
        </header>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="lg:p-10 bg-about text-center">
            <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125] ml-12 xs:p-[21px] xs:mt-[7px]">
              Team Leads
            </h1>
          </div>
          <Tabs>
          <TabList   className="flex justify-start xs:justify-center xs:gap-9 lg:gap-[5rem] shadow-md p-3">
    
        <Tab
           className={`text-[#000000] mont-serif text-xl cursor-pointer mt-2 ml-8 ${
            activeTab === 0 ? 'border-b-4 transition-all duration-500' : ''
          } focus:outline-none`}
          onClick={() => handleTabClick(0)}
        >
         Given
        </Tab>
        <Tab
           className={`text-[#000000] mont-serif text-xl cursor-pointer mt-2 ${
            activeTab === 1 ? 'border-b-4 transition-all duration-500' : ''
          } focus:outline-none`}
          onClick={() => handleTabClick(1)}
        >
         Received
        </Tab>
       
      </TabList>

            <TabPanel>
          <Leagueleadtable glass={img}/>
            </TabPanel>
            <TabPanel>
         <Receivedtabe  glass={img}/>
            </TabPanel>
           
          </Tabs>
     
        </div>
      </main>
      <Footer/>
   </>
  )
}

export default Leagueleads
