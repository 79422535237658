import React from "react";

import { Link } from "react-router-dom";
import guest from "../../../assets/guest.png";

export default function GuestRegestration() {
  return (
    <>
      <div className="--guest-layout  h-screen bg-banner bg-11 ">
        <div className="--content-started mb-3   bg-white lg:w-[95%] lg:mx-auto rounded-md relative lg:top-8 lg:h-[92vh] fade-in-left  xs:relative xs:top-[4rem] xs:h-[70vh] ">
          <div className="--contet-text ">
            <h4 className="text-[#005125] mont-serif text-center lg:text-[25px] font-semibold relative lg:top-[1.5rem] xs:top-[3rem] xs:text-[20px]">
              Welcome to DTEC RABBIT
            </h4>
          </div>

          <div className="--img lg:mt-4 fade-in-right">
            <img
              src={guest}
              className="mx-auto lg:h-[21rem] relative lg:top-[3rem] xs:mt-[6rem]"
              alt=""
            />
          </div>
          <div className="--button-action--path">
            <div
              className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[9rem] xs:mt-[8rem]"
              style={{ alignItems: "center" }}
            >
              <Link to="/guest-loginemail">
                <button
                  className="xs:w-[15rem] mont-serif text-black button-1 border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[21rem]"
                  style={{ borderRadius: 11, fontSize: 14 }}
                >
                  Register via Email
                </button>
              </Link>
           
           <Link to="/guest-verifyphone" >
              <button
                className="hover:bg-green-200  xs:w-[15rem] border-green-700 mont-serif text-black  border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[21rem]"
                style={{ borderRadius: 11, fontSize: 14, border: "1px solid" }}
              >
                Register via Phone no 
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
