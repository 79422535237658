import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import {MdOutlineManageSearch} from "react-icons/md"
import { AiFillHome } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";

import {
  BsPeopleFill,
  BsFillShieldLockFill,
  BsFillChatFill,
} from "react-icons/bs";
import { HiLink } from "react-icons/hi";

import { HiRocketLaunch } from "react-icons/hi2";
import { FaCalculator } from "react-icons/fa";
import { RxExit } from "react-icons/rx";
import icona from "../assets/welcomepage/icon-d.png";

//
export default function SideMobile() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <nav className="bg-card">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
              <button
                onClick={toggleNavbar}
                className="inline-flex items-center justify-center p-2  text-white focus:outline-none "
              >
                {isOpen ? (
                  <FiX className="h-6 w-6 focus:outline-0 hover:border-none text-white" />
                  ) : (
                    <FiMenu className="h-6 w-6 focus:outline-0 hover:border-none text-white" />
                    )}
              </button>
            </div>
                    <MdOutlineManageSearch className="text-[33px] absolute text-white"  onClick={toggleDropdown} />
                    {isDropdownOpen && (
                <div className="absolute mt-2 w-[23.2rem] bg-white rounded-md drop-filter lg:left-[188px] xs:top-[5rem] scale-up-ver-top z-10	">
                  
                  <div className="relative text-gray-600 focus-within:text-gray-400 mt-4 mb-4">
    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
      <button
        type="submit"
        className="p-1 focus:outline-none focus:shadow-outline"
      >
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          viewBox="0 0 24 24"
          className="w-6 h-6"
        >
          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </span>
    <input
      type="search"
      name="q"
      className="py-2 text-sm text-black bg-white rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900 w-[42vh]"
      placeholder="Search..."
      autoComplete="off"
    />
  </div>
                  <div className="py-1">
                    {/* // */}
                    {/* text  */}
                    <div className="flex flex-row items-center gap-[9rem] lg:mt-[6px]">
                      <h1 className="mont-serif text-[20px] font-semibold ml-4 text-[#007033] ">
                        Skills in Demand{" "}
                      </h1>
                      <RxCross1
                        className="lg:text-[20px] cursor-pointer"
                        onClick={toggleDropdown}
                      />
                    </div>
                    {/* <hr  className='border-dashed border-[1px] mt-3 border-[#007033] ' /> */}
                    {/* // flter option  */}
                    <div className="flex-wrap justify-start mt-[13px] gap-3 lg:w-[max-content]">
                      <div className=" xs:flex-wrap lg:flex gap-2 ml-[2rem]">
                        <button className="filled-true p-[3px] lg:lg:w-[84px] xs:w-[6rem] mont-serif text-white ">
                          no.1
                        </button>
                        <button className=" xs:ml-[4px] bg-default  p-[3px] lg:w-[185px] xs:w-[10rem] mont-serif ">
                          Second Option
                        </button>
                        <button className="  xs:ml-[4px] bg-default  p-[3px] lg:w-[142px] mont-serif   xs:w-[6rem] ">
                          # three
                        </button>
                        <button className=" xs:mt-[6px]  bg-default  p-[3px] lg:w-[176px] mont-serif  xs:w-[8rem]">
                          next choice
                        </button>
                      </div>
                    </div>
                    <div className="flex-wrap justify-start mt-3 gap-3 lg:w-[max-content]">
                      <div className=" xs:flex-wrap lg:flex gap-2 ml-[2rem]">
                        <button className="filled-true p-[3px] lg:lg:w-[84px] xs:w-[6rem] mont-serif text-white ">
                          no.1
                        </button>
                        <button className=" xs:ml-[4px] bg-default  p-[3px] lg:w-[185px] xs:w-[10rem] mont-serif ">
                          Second Option
                        </button>
                        <button className="  xs:ml-[4px] bg-default  p-[3px] lg:w-[142px] mont-serif   xs:w-[6rem] ">
                          # three
                        </button>
                        <button className=" xs:mt-[6px]  bg-default  p-[3px] lg:w-[176px] mont-serif  xs:w-[8rem]">
                          next choice
                        </button>
                      </div>
                    </div>
                    {/* -- filter -a option ended */}
                    {/* <hr  className='border-dashed border-[1px] mt-3 border-[#007033] ' /> */}

                    <hr className="relative lg:top-[1rem]" />
                    <h1 className="mont-serif text-[20px] font-semibold ml-4 text-[#007033] mt-6 ">
                      Popular Company Research{" "}
                    </h1>

                    {/* ---vb */}
                    <div className="flex-wrap justify-start mt-[13px] gap-3 lg:w-[max-content]">
                      <div className=" xs:flex-wrap lg:flex gap-2 ml-[2rem]">
                        <button className="filled-true p-[3px] lg:lg:w-[84px] xs:w-[6rem] mont-serif text-white ">
                          no.1
                        </button>
                        <button className=" xs:ml-[4px] bg-default  p-[3px] lg:w-[185px] xs:w-[10rem] mont-serif ">
                          Second Option
                        </button>
                        <button className="  xs:ml-[4px] bg-default  p-[3px] lg:w-[142px] mont-serif   xs:w-[6rem] ">
                          # three
                        </button>
                        <button className=" xs:mt-[6px]  bg-default  p-[3px] lg:w-[176px] mont-serif  xs:w-[8rem]">
                          next choice
                        </button>
                      </div>
                    </div>
                    <div className="flex-wrap justify-start mt-3 gap-3 lg:w-[max-content] lg:mb-8">
                      <div className=" xs:flex-wrap lg:flex gap-2 ml-[2rem]">
                        <button className="filled-true p-[3px] lg:lg:w-[84px] xs:w-[6rem] mont-serif text-white ">
                          no.1
                        </button>
                        <button className=" xs:ml-[4px] bg-default  p-[3px] lg:w-[185px] xs:w-[10rem] mont-serif ">
                          Second Option
                        </button>
                        <button className="  xs:ml-[4px] bg-default  p-[3px] lg:w-[142px] mont-serif   xs:w-[6rem] ">
                          # three
                        </button>
                        <button className=" xs:mt-[6px]  bg-default  p-[3px] lg:w-[176px] mont-serif  xs:w-[8rem]">
                          next choice
                        </button>
                      </div>
                    </div>
                    {/* ----b  */}
                  </div>
                </div>
              )}
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">


              <div className="flex-shrink-0 flex items-center">
                <Link
                  to="/"
                  className="text-white text-xl font-bold font-all mont-serif"
                >
                  DTEC RABBIT
                </Link>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`sm:hidden transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "h-[27rem]" : "h-0"
          }`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className="text-white hover:text-white lg:block px-3 py-2  text-base border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3 "
            >
              Home <AiFillHome className="text-white" />
            </Link>
            <Link
              to="/aboutus"
              className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base l:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              About
                    <BsPeopleFill   className="text-white relative right-[1px]" />

            </Link>
            <Link
              to="/members"
              className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif  flex items-center justify-center flex-row-reverse	font-bold  gap-3 "
            >
             <span className="relative left-[6px]"> Members </span>   <img src={icona}   className="h-[23px] relative left-[9px]" alt="" />
            </Link>
            <Link
              to="/league"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Leagues      <HiRocketLaunch />
            </Link>
            <Link
              to="/event"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Events   <FaCalculator className="text-white" />
            </Link>
            <Link
              to="/chats"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Chats      <BsFillChatFill  className="text-white"/>
            </Link>
            <Link
              to="/privacy"
              className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center	font-bold  gap-3"
            >
                  <BsFillShieldLockFill  className="text-white"/>
              Privacy
            </Link>
            <Link
              to="/referal"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base  border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Referal    <HiLink  className="text-white"/>
            </Link>
{/*  */}
<hr className="border-dashed border-[px] border-white" />
<Link
              to="/login"
              className="mt-[20px] text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Logout    <HiLink  className="text-white"/>
            </Link>
{/*  */}
          </div>
        </div>
      </nav>
    </>
  );
}
