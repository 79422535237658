import React from "react";
import { Link } from "react-router-dom";
import Phone from "../../../assets/phone.jpg"
export default function RegisterPhone() {
  return (
    <>
      <div className="guest-layout  h-screen bg-banner bg-11">
        <div className="content-started mb-3 bg-white lg:w-[50%] mx-auto lg:relative lg:top-[6rem] rounded-md lg:h-[78vh] xs:relative xs:top-[6rem] xs:h-[70vh]">
          <h1 className="text-center font-semibold mont-serif text-green-700 text-[23px]">
            Welcome to Dtec Rabbit
          </h1>


<img  className="lg:h-[22rem] lg:mx-auto fade-in-left lg:mt-[11px] xs:h-[18rem] xs:mx-auto" src={Phone} alt="" />


          <div className="flex items-center border-b border-gray-300 py-2 w-[53%] mx-auto xs:mt-[3rem]">
      <div className="w-24 mr-2">
        <input
          type="text"
          className="w-full border-0 focus:outline-none"
          placeholder="+33"
        />
      </div>
      <div className="flex-1">
        <input
          type="text"
          className="w-full border-0 focus:outline-none"
          placeholder="Phone number"
        />
      </div>
    </div>

<div className="flex justify-center">

<Link to="/guest-verify-otp" >
<button class=" mont-serif text-black button-1 border-0 py-1 px-8 focus:outline-none  text-lg lg:w-[18rem] xs:w-[15rem] rounded-md mt-[6rem]" >next</button>
</Link>

</div>

        </div>
      </div>
    </>
  );
}
