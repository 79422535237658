import React from "react";
import { useRoutes } from "react-router-dom";
import Memberdecription from "./Components/Members/Memberdecription";
import Upcommingeventpage from "./Components/Events/Upcommingeventpage";
import Aboutus from "./Components/Aboutus";
import Homepage from "./Components/Home/Homepage";
import Membercomponent from "./Components/Membercomponent";
import Leaguehomea from "../src/Components/Leagues/leagueshome/Leaguehomea";
import Eventstock from "./Components/Events/Eventstock";
import Userchat from "./Components/Chats/Userchat";
import Privacystatic from "./Components/Privacysection/Privacystatic";
import Referallink from "./Components/Referall/Referallink";
import ref from "../src/assets/welcomepage/sharelink.png";
import Leaderbordhome from "./Components/Leaderboard/Leaderbordhome";
import Formupload from "./Components/Home/Formupload";
import Leagueleads from "./Components/Leagues/Leaguelead/Leagueleads";
import Welcomesocial from "./Components/pages/Welcomesocial";
import bgimage from "../src/assets/welcomepage/imagecard.png";
import Neweventform from "./Components/Events/Registerevent/Neweventform";
import Approvalpendingform from "./Components/Events/Registerevent/Approvalpendingform";
import Welcomemember from "../src/Components/pages/Welcomemember";
import Registercomapny from "../src/Components/pages/Registercomapny";
import Pendingverification from "../src/Components/pages/Pendingverification";
import Leagueroast from "./Components/Leagues/Leagueroaster.jsx/Leagueroast";
import Slipshome from "./Components/Leagues/leagueSlip/Slipshome";
import Leaguepage from "./Components/Leagues/Leaguepage";
import AdminhomeMain from "./Components/Admin/AdminHome/AdminhomeMain";
import LeadershipTeam from "./Components/Admin/adminLeadershipTeam/LeadershipTeam";
import AdminLeagueList from "./Components/Admin/AdminHome/AdminLeagueList";
import Cencom from "./Components/Admin/AdminCencom/Cencom";
import MemberAdminmain from "./Components/Admin/MemberAdmin/MemberAdminmain";
import AdminEventmain from "./Components/Admin/AdminEvent/AdminEventmain";
import Chatmain from "./Components/Admin/AdminComplain/Chatmain";
import AdminPrivacy from "./Components/Admin/AdminPrivacy";
import Welcomepage from "./Components/pages/Welcomepage";
import Otphone from "./Components/pages/Otpphone";
import Verifycode from "./Components/pages/Verifycode";
import GuestMain from "./Components/pages/Guest/GuestMain";
import GuestRegestration from "./Components/pages/Guest/GuestRegestration";
import RegestrationEmail from "./Components/pages/Guest/RegestrationEmail";
import GuestOtpEmail from "./Components/pages/Guest/GuestOtpEmail";
import GuestRegisterForm from "./Components/pages/Guest/GuestRegisterForm";
import GuestPendinfVerification from "./Components/pages/Guest/GuestPendinfVerification";
import RegisterPhone from "./Components/pages/Guest/RegisterPhone";
function App() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Homepage />,
    },

    {
      path: "/aboutus",
      element: <Aboutus />,
    },
    {
      path: "/members",
      element: <Membercomponent />,
    },
    {
      path: "/league",
      element: <Leaguehomea bgimage={bgimage} />,
    },
    {
      path: "/event",
      element: <Eventstock />,
    },
    {
      path: "/chats",
      element: <Userchat />,
    },
    {
      path: "/privacy",
      element: <Privacystatic />,
    },
    {
      path: "/referal",
      element: <Referallink refImg={ref} />,
    },
    {
      path: "/leaderboard",
      element: <Leaderbordhome />,
    },
    {
      path: "/formuploads",
      element: <Formupload />,
    },
    {
      path: "/description",
      element: <Memberdecription />,
    },
    {
      path: "/leagueleads",
      element: <Leagueleads />,
    },
    {
      path: "/leagueroast",
      element: <Leagueroast />,
    },
    {
      path: "/leagueslips",
      element: <Slipshome />,
    },
    {
      path: "/leaguemeeting",
      element: <Leaguepage />,
    },
    {
      path: "/Upcommingevents",
      element: <Upcommingeventpage />,
    },

    {
      path: "/registeration-new-event",
      element: <Neweventform />,
    },
    // Logout reouting

{

  path:"/guest",
  element:<GuestMain/>
}
,

{
path:"/guest-register",
element:<GuestRegestration/>
},
  
{
path:"/guest-loginemail",
element: <RegestrationEmail/>
}
,
{
path:"/guest-verify-otp",
element:<GuestOtpEmail/>
}
,
{

  path:"/guest-regestration",
  element:<GuestRegisterForm/>
},

{

  path:'/guest-verificaton-approval',
  element: <GuestPendinfVerification/>
}
,

{
path:'/guest-verifyphone',
element:<RegisterPhone/>
},
// 
{
      path: "/pendingapproval",
      element: <Approvalpendingform />,
    },
    {
      path: "/login",
      element: <Welcomesocial />,
    },
    {
      path: "/member-regestration",
      element: <Welcomemember />,
    },
    {
      path: "/regestration-form-member",
      element: <Registercomapny />,
    },
    {
      path: "/verification",
      element: <Pendingverification />,
    },
    {
      path: "/forgot-password",
      element: <Welcomepage />,
    },
    {
      path: "/otp-phone",
      element: <Otphone />,
    },
    {
      path: "/otp-email",
      element: <Welcomepage />,
    },
    {
      path: "/otp",
      element: <Verifycode />,
    },
    // admin
    {
      path: "/admin",
      element: <AdminhomeMain />,
    },
    {
      path: "/admin-leadership-team",
      element: <LeadershipTeam />,
    },
    {
      path: "/admin-leagueList",
      element: <AdminLeagueList />,
    },
    {
      path: "/admin-Cencom",
      element: <Cencom />,
    },
    {
      path: "/admin-member",
      element: <MemberAdminmain />,
    },
    {
      path: "/admin-Event",
      element: <AdminEventmain />,
    },
    {
      path: "/admin-complain",
      element: <Chatmain />,
    },
    {
      path: "/admin-privacy",
      element: <AdminPrivacy />,
    },
  ]);

  return <>{routes}</>;
}

export default App;
