import React, { useState } from "react";
import { Link } from "react-router-dom";


export default function RegestrationEmail() {
  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };
  return (
    <>
     <div className="guest-layout  h-screen bg-banner bg-11">
      <div className="content-started mb-3   bg-white lg:w-[95%] lg:mx-auto rounded-md relative lg:top-8 lg:h-[92vh] fade-in-left 
    xs:top-[2rem] xs:text-[20px] xs:h-[77vh]  ">
<div className="content-form">

<h4 className="text-[#005125] mont-serif text-center lg:text-[25px] font-semibold relative lg:top-[1.5rem] xs:text-[20px] xs:top-[1rem]" >
              Welcome to DTEC RABBIT
            </h4>

{/*  */}

<form
            onSubmit={handleSubmit}
            className="w-[50%] mx-auto lg:mt-[10rem] xs:mt-[5rem] "
          >
            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field1"
              >
              Enter your account email ID
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field1"
                id="field1"
                value={formData.field1}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field2"
              >
            Enter Password
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field2"
                id="field2"
                value={formData.field2}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field3"
              >
        New Passwrod
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field3"
                id="field3"
                value={formData.field3}
                onChange={handleChange}
              />
            </div>

         

       

    
            <div className="flex flex-col gap-3 justify-center items-center mt-[3rem] xs:mt-[8rem]">
          <Link to="/guest-verify-otp" >
              <button
                className="border border-green-900  w-[17rem] p-[4px] rounded-md mont-serif fade-in-right mb-12"
                type="submit"
              >
Next
              </button>
              </Link>
            </div>
          </form>

</div>



      </div>
     </div>
    </>
  )
}
