import React, { useState } from "react";
import {  FaSearch } from "react-icons/fa";
import Chatstock from "./Chatstock";
import Searchtab from "../Searchtab";
import Sidebar from "../Sidebar";
import Footer from "../Footer"
import SideMobile from "../SideMobile";
import Logout from "../Home/Logout.png";
import { RxCross1 } from "react-icons/rx";
import {  useNavigate } from "react-router-dom";

export default function Userchat() {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };
  return (
    <>
  {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



<div className="sm:hidden lg:block">
    <Sidebar toggleModal={toggleModal}/>
    </div>
<div className="lg:hidden sm:block">
<SideMobile/>
</div>

      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="xs:hidden lg:block header bg-white shadow py-4 px-4">
         <Searchtab/>
        </header>
        <div className="main-content flex flex-col flex-grow">
          <div className="lg:container lg:mx-auto">
            <div className="min-w-full border rounded lg:grid lg:grid-cols-3 ">
              <div className="border-r border-gray-300 lg:col-span-1">
                <div className="mx-3 my-3 bg-profile p-[20px] rounded-md">
                    <h6 className="mont-serif text-white text-left font-semibold relative bottom-2"> Your Messages</h6>
                  <div className="relative text-gray-600">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <FaSearch />
                    </span>
                    <input
                      type="search"
                      className="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
                      name="search"
                      placeholder="Search"
                      required=""
                    />
                  </div>
                </div>
                <ul className="overflow-auto h-[32rem] xxl:h-[29rem]">
                  <li>
                    <a
                      href="//"
                      className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none"
                    >
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/09/12/12/14/man-3672010__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Jhon Don
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            25 minutes
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          bye
                        </span>
                      </div>
                    </a>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out bg-gray-100 border-b border-gray-300 cursor-pointer focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2016/06/15/15/25/loudspeaker-1459128__340.png"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Same
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            50 minutes
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good night
                        </span>
                      </div>
                    </span>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>

                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>


                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>

                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                  </li>
                </ul>
              </div>
              <div className="hidden lg:col-span-2 lg:block">
                <div className="w-full">
                  <div className="relative flex items-center p-3 border-b border-gray-300 bg-profile ">
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                      alt="username"
                    />
                    <span className="block ml-2 font-bold text-gray-600">
                      Emma
                    </span>
                    <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3"></span>
                  </div>

                  <Chatstock />
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
}
