import React, { useState } from 'react';


function Leagueleadslip() {
    const [formData, setFormData] = useState({
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log(formData);
      };

 
      const [insideButtonSelected, setInsideButtonSelected] = useState(true);
      const [outsideButtonSelected, setOutsideButtonSelected] = useState(false);
    
      const handleInsideButtonClick = () => {
        setInsideButtonSelected(true);
        setOutsideButtonSelected(false);
      };
    
      const handleOutsideButtonClick = () => {
        setInsideButtonSelected(false);
        setOutsideButtonSelected(true);
      };
    

  return (
    <>
          <form onSubmit={handleSubmit}   className="xs:mt-8 w-[50%] mx-auto lg:mt-[3rem]">
      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field1">
        To
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field1"
          id="field1"
          value={formData.field1}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field2">
         League Lead Type
        </label>
        <div   className="flex flex-row justify-center gap-[4px]">
        <button
        className={`p-2 m-2 rounded-md ${
          insideButtonSelected  ? 'bg-green-700 text-white' : 'bg-white border border-green-700 text-black'
        }`}
        onClick={handleInsideButtonClick}
      >
        Inside 
      </button>
      <button
        className={`p-2 m-2 rounded-md ${
          outsideButtonSelected ? 'bg-green-700 text-white' : 'bg-white border border-green-700 text-black'
        }`}
        onClick={handleOutsideButtonClick}
      >
        Outside 
      </button>
        </div>
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field3">
         League Lead Status
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field3"
          id="field3"
         value=""
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field4">
        League Lead
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field4"
          id="field4"
          value={formData.field4}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field5">
         Telephone
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field5"
          id="field5"
          value={formData.field5}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field5">
        Email
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field5"
          id="field5"
          value={formData.field5}
          onChange={handleChange}
        />
      </div>

    
<div   className="lg:mb-[4rem] flex flex-col gap-3 justify-center items-center mt-[3rem] mb-4">
      <button     className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left"   type="submit">Confirm</button>
      </div>

    </form>
    </>
  )
}

export default Leagueleadslip
