import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function GuestRegisterForm() {
  const [selectedOption, setSelectedOption] = useState("option1");

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }


  return (
    <>
<div className="guest-layout  h-screen bg-banner bg-11">
  
  <div className="content-started mb-3   bg-white lg:w-[50%] mx-auto lg:relative lg:top-[2rem] rounded-md xs:relative xs:top-[34px]">

  <div className="fade-in-left  lg:h-[95vh] ">
              <h3 className="text-md lg:text-[21px] font-semibold text-green-700 mont-serif leading-tight  text-center lg:mb-8 relative lg:top-4  xs:top-[10px]">
                Dtec Rabbit 
              </h3>
           
           <div className="--form-action--submission lg:h-[78vh] overflow-y-auto">
        <form action="" className="relative xs:left-[1rem]">

{/* --a  */}
<div className="xs:flex xs:flex-row xs:items-center flex flex-col">
                  <label
                    className="xs:relative xs:top-4  lg:left-[8rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    Full Name :
                  </label>
                  <input
                    className="xs:relative xs:left-[23px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[63vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>
{/* ---  b  */}

<div className="flex lg:flex-row xs:flex-col lg:gap-[1.5rem] justify-center">
                  <div className="item-a xs:flex xs:flex-row xs:items-center">
                    <label
                      className="xs:relative xs:top-4 lg:left-[0rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                      htmlFor="full-name"
                    >
                      Phone Number
                    </label>
                    <input
                      className="xs:relative xs:left-[7px] lg:w-[13rem] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto m-auto"
                      type="text"
                      id="full-name"
                    />
                  </div>
                  <div className="item-b xs:flex xs:flex-row xs:items-center">
                    <label
                      className="xs:relative xs:top-[19px] lg:left-[0rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                      htmlFor="full-name"
                    >
                      Email ID
                    </label>
                    <input
                      className="xs:relative xs:left-[30px]  lg:w-[13rem] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto m-auto"
                      type="text"
                      id="full-name"
                    />
                  </div>
                </div>
{/* ---c  */}
<div className="xs:flex xs:flex-row xs:items-center xs:gap-[44px] xs:mt-8 flex flex-col">
                  <label
                    className="lg:left-[8rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] lg:mb-[1.5rem] "
                    htmlFor="full-name"
                  >
                    Choose League
                  </label>
                  <select
                    className="border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:mt-4  lg:w-[60vh] xs:w-[11rem] mx-auto"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
 </div>
{/* --- d  */}
<div className="xs:flex xs:flex-row xs:items-center flex flex-col">
                  <label
                    className="xs:relative xs:top-4  lg:left-[8rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    License no :
                  </label>
                  <input
                    className="xs:relative xs:left-[23px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[60vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>
{/* e */}

<div className="flex lg:flex-row xs:flex-col lg:gap-[1.5rem] justify-center">
                  <div className="item-a xs:flex xs:flex-row xs:items-center">
                    <label
                      className="xs:relative xs:top-4 lg:left-[0rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                      htmlFor="full-name"
                    >
                      License type
                    </label>
                    <input
                      className="xs:relative xs:left-[7px] lg:w-[13rem] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto m-auto"
                      type="text"
                      id="full-name"
                    />
                  </div>
                  <div className="item-b xs:flex xs:flex-row xs:items-center">
                    <label
                      className="xs:relative xs:top-[19px] lg:left-[0rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                      htmlFor="full-name"
                    >
                      Office type
                    </label>
                    <input
                      className="xs:relative xs:left-[30px]  lg:w-[13rem] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto m-auto"
                      type="text"
                      id="full-name"
                    />
                  </div>
                </div>
{/* f  */}
<div className="xs:flex xs:flex-row xs:items-center flex flex-col">
                  <label
                    className="xs:relative xs:top-4  lg:left-[8rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                     Company owner / Fonder :
                  </label>
                  <input
                    className="xs:relative xs:left-[23px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[60vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>
{/* g  */}
<div className="xs:flex xs:flex-row xs:items-center flex flex-col">
                  <label
                    className="xs:relative xs:top-4  lg:left-[8rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                   Website :
                  </label>
                  <input
                    className="xs:relative xs:left-[23px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[60vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>
{/* d */}
<div className="mt-4 xs:ml-[6rem]">
                  <label
                    className="text-green-700  text-[13px] ml-[-6rem] font-bold  mont-serif relative top-[8px]"
              
                  >
                    Product Detail
                  </label>
                  <textarea
                    id="textarea"
                    type="textarea"
                    className="xs:relative xs:left-[22px] xs:top-[-14px] relative lg:top-[19px]  block lg:w-[63vh] px-4 py-2 mt-2 text-green-700 bg-white border border-green-800 rounded-md focus:border-blue-500 focus:outline-none focus:ring mx-auto"
                    defaultValue={""}
                  />
                </div>

{/* e  */}
<div className="mt-4 xs:ml-[6rem]">
                  <label
                    className="text-green-700  text-[13px] ml-[-6rem] font-bold  mont-serif relative top-[8px]"
              
                  >
Product page
                  </label>
                  <textarea
                    id="textarea"
                    type="textarea"
                    className="xs:relative xs:left-[22px] xs:top-[-14px] relative lg:top-[19px]  block lg:w-[63vh] px-4 py-2 mt-2 text-green-700 bg-white border border-green-800 rounded-md focus:border-blue-500 focus:outline-none focus:ring mx-auto"
                    defaultValue={""}
                  />
                </div>


        </form>
           </div>

<div className="--content-action-button flex justify-center mx-auto">


<Link to="/guest-verificaton-approval" >
<button class=" lg:w-[278px]  mont-serif text-black bg-white text border-0 p-[0px] focus:outline-none rouded text-lg next rounded-md lg:mt-8 xs:mt-4 xs:mb-4 " >Submit Application</button>
</Link>
</div>

            </div>
  </div>
  </div>  
    </>
  )
}
