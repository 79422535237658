import React from "react";
import guest from "../../../assets/guest.png";
import { Link } from "react-router-dom";

export default function GuestMain() {
  return (
    <>
      <div className="--guest-layout  h-screen bg-banner bg-11 ">
        <div className="--content-started mb-3   bg-white lg:w-[95%] lg:mx-a xs:relative xs:top-[4rem] xs:h-[70vh] uto rounded-md relative lg:top-8 lg:left-[4rem] lg:h-[92vh] fade-in-left   ">
          <div className="--contet-text ">
            <h4 className="text-[#005125] mont-serif text-center lg:text-[25px] font-semibold relative lg:top-[1.5rem] xs:top-[5rem] xs:text-[20px]">
              Welcome to DTEC RABBIT
            </h4>
            <p className="mont-serif text-[#005125] text-center lg:mt-[75px] text-[16px] font-semibold xs:mt-[4rem] xs:relative xs:top-[2rem]">
              In order to get the full experience of the app , kindly registerd
              as member and make payment
            </p>
          </div>

          <div className="--img lg:mt-4 fade-in-right">
            <img src={guest} className="mx-auto lg:h-[323px] xs:relative xs:top-[2rem]" alt="" />
          </div>
          <div className="--button-action--path xs:mt-[8rem]">
            <div
              className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[4rem] xs:mt-4"
              style={{ alignItems: "center" }}
            >
              <Link to="/guest-register">
                <button
                  className="xs:w-[15rem] mont-serif text-black button-1 border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[21rem]"
                  style={{ borderRadius: 11, fontSize: 14 }}
                >
                  Procceed to Registration
                </button>
              </Link>
              <Link to="/member-regestration">
                <button
                  className="xs:w-[15rem] border-green-700 mont-serif text-black  border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[21rem]"
                  style={{
                    borderRadius: 11,
                    fontSize: 14,
                    border: "1px solid",
                  }}
                >
                  Go Back
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
