import React, { useState } from "react";

function Recordcbr() {
  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };
// 
const [newButtonSelected, setNewButtonSelected] = useState(true);
  const [repeatButtonSelected, setRepeatButtonSelected] = useState(false);

  const handleNewButtonClick = () => {
    setNewButtonSelected(true);
    setRepeatButtonSelected(false);
  };

  const handleRepeatButtonClick = () => {
    setNewButtonSelected(false);
    setRepeatButtonSelected(true);
  };
// 
const [button1Selected, setButton1Selected] = useState(true);
const [button2Selected, setButton2Selected] = useState(false);
const [button3Selected, setButton3Selected] = useState(false);

const handleButton1Click = () => {
  setButton1Selected(true);
  setButton2Selected(false);
  setButton3Selected(false);
};

const handleButton2Click = () => {
  setButton1Selected(false);
  setButton2Selected(true);
  setButton3Selected(false);
};

const handleButton3Click = () => {
  setButton1Selected(false);
  setButton2Selected(false);
  setButton3Selected(true);
};
  return (
    <>
      <form onSubmit={handleSubmit}   className="xs:mt-[6px] w-[50%] mx-auto lg:mt-[3rem]">
        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field1"
          >
            Thankyou to :
          </label>
          <input
                className="mont-serif  appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="field1"
            id="field1"
            value={formData.field1}
            onChange={handleChange}
          />
        </div>

        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field2"
          >
            Bussiness Type :
          </label>

          <div   className="flex flex-row justify-center items-center mx-auto gap-2">
          <button
        className={`border ${
          newButtonSelected ? 'bg-green-700 text-white' : 'bg-white border border-green-700 text-black'
        } border-green-900 w-[19rem] rounded-md p-[9px] mont-serif fade-in-right`}
        onClick={handleNewButtonClick}
        type="submit"
      >
        New
      </button>
      <button
        className={`border ${
          repeatButtonSelected ? 'bg-green-700 text-white rounded-md' : 'bg-white border border-green-700 text-black'
        } bg-green-600 text-black w-[19rem] p-[9px] rounded-md mont-serif fade-in-right`}
        onClick={handleRepeatButtonClick}
        type="submit"
      >
        Repeat
      </button>
          </div>
        </div>

        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field3"
          >
            Meeting Location:
          </label>
          <div className="flex flex-row justify-center items-center mx-auto gap-[3px]">
      <button
        className={`border ${
          button1Selected ? 'bg-green-700 rounded-md text-white' : 'bg-white'
        } border-green-900 rounded-md text-black w-[19rem] p-[9px] mont-serif fade-in-right`}
        onClick={handleButton1Click}
        type="submit"
      >
  inside
      </button>
      <button
        className={`border ${
          button2Selected ? 'bg-green-700 rounded-md text-white' : 'bg-white'
        } bg-green-600  border border-green-700 rounded-md text-black w-[19rem] p-[9px] mont-serif fade-in-right`}
        onClick={handleButton2Click}
        type="submit"
      >
       outside
      </button>
      <button
        className={`border ${
          button3Selected ? ' bg-green-700 rounded-md text-white' : 'text-[#005125] bg-white'
        } border-green-900 w-[19rem] p-[9px] mont-serif fade-in-right rounded-md`}
        onClick={handleButton3Click}
        type="submit"
      >
        tier 3+
      </button>
    </div>
        </div>

        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field4"
          >
          Comments
          </label>
          <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="field4"
            id="field4"
            value={formData.field4}
            onChange={handleChange}
          />
        </div>

    
        <div   className="flex flex-col gap-3 justify-center items-center mt-[3rem] lg:mb-9 xxl:mb-9">
          <button
                className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left xxl:mb-8"
            type="submit"
          >
            Confirm
          </button>
       
        </div>
      </form>
    </>
  );
}

export default Recordcbr;
