import React from 'react'
import  gif from "../../pages/timer.gif"
import { Link } from 'react-router-dom'

export default function GuestPendinfVerification() {
  return (
    <>
      
<div className="guest-layout  h-screen bg-banner bg-11">


<div className="content-started mb-3   bg-white lg:w-[50%] lg:h-[93vh]  mx-auto lg:relative lg:top-[2rem] rounded-md xs:h-[70vh] xs:relative xs:top-[6rem]">
<div className="--contet-text ">
            <h4 className="text-[#005125] mont-serif text-center lg:text-[25px] font-semibold relative lg:top-[1.5rem]">
              Welcome to DTEC RABBIT
            </h4>
          </div>
        

<p className='text-[#005125]  mont-serif text-center lg:mt-[3rem] text-[17px]' >verification pending</p>

          <div className="--img lg:mt-4 fade-in-right">
    <img src={gif} className="mx-auto lg:h-[14rem] relative lg:top-[4srem] xs:h-[10rem]" alt="" />
</div>

<div className="--bottom-content">
    <h2 className="mont-serif font-semibold text-[20px] text-center mt-8">
        Thanks for your Submission!
    </h2>

    <h6 className="mont-serif text-[#005125]  text-center mt-8">we have received your application kindly wait for admin  to approve</h6>
</div>

<div className="--- xs:mt-[5rem]">
<div
    className="forgot-password flex flex-col gap-3 justify-center lg:mt-[6rem] "
    style={{ alignItems: "center" }}
  >
    <button
      className=" lg:w-[278px]  mont-serif text-black  xs:w-[16rem] bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg next  "
      style={{ borderRadius: 11, fontSize: 14 }}
    >
      Contact us 
    </button>
   
   <Link to="/guest" >
   
    <button
      className=" lg:w-[278px]  mont-serif text-black  xs:w-[16rem] shadow-md drop-shadow-md bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg "
      style={{ borderRadius: 11, fontSize: 14 }}
    >
      Go back
    </button>
    </Link>
  </div>
</div>


</div>
</div>


    </>
  )
}
