import React from "react";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

import logo from "./logo.png";
import Logo from "../../assets/welcomepage/logo-1.png";
import { useState } from "react";
export default function Welcomesocial() {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const Routememberform = () => {
    navigate(`/member-regestration`);
  };
  //  form fields
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(""); // Added token state
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate the form
    if (username === "developer@gmail.com" && password === "dtech1234") {
      setSuccess(true);
      console.log("Form is valid. Login successful!");
      navigate(`/`);
    } else {
      setSuccess(false);
      console.log("Form is not valid. Login failed!");
    }

    // Clear the form fields
    // setUsername("");
    // setPassword("");
  };

  //

  return (
    <>
      <div>
        <section className="text-gray-600 body-font bg-11 lg:h-[100vh] bg-banner xs:h-[101vh] ">
          <div className="flex flex-row gap-[11rem] items-center  lg:hidden">
            <img src={Logo} className="mt-4 ml-1" alt="" />
            <div className="flex fle-row gap-[5px]">
              <button
                // onClick={Routelogin}
                style={{ borderRadius: "5px", fontSize: "14px" }}
                className="xs:w-[5rem] mont-serif text-black bg-white border-0  focus:outline-none rounded text-lg"
              >
                Login
              </button>

              <button
                style={{ borderRadius: "6px", fontSize: "14px" }}
                className="xs:w-[5rem] mont-serif text-black bg-white border-0  focus:outline-none rounded text-lg"
              >
                Contact us
              </button>
            </div>
          </div>

          <div className="lg:container px-5 py-4 lg:mx-auto xs:mt-4">
            <div className="flex flex-wrap -mx-4 -mb-10 text-center lg:gap-8">
              <div className="fade-in-left lg:w-[44%] md:w-1/2 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-1  lg-w-[43%] lg:h-[92vh] bg-white">
                <div
                  className="flex flex-col justify-center lg:gap-[14px] lg:mt-[-2.9rem]"
                  style={{ alignItems: "center" }}
                >
                  <h1 className="sm:text-3xl text-2xl  title-font  text-[#005125] mont-serif font-bold lg:mt-[62px] mb-[15px]">
                    Welcome to 360 Community
                  </h1>
                  <div>
                    <form
                      onSubmit={handleSubmit}
                      className="xs:flex xs:flex-col xs:gap-8"
                    >
                      <div className="xs:flex xs:flex-row xs:items-center xs:gap-4">
                        <label
                          className="xs:relative xs:top-[15px]  block text-green-700 font-bold lg:mb-2 mont-serif relative lg:top-[18px] lg:right-[125px] lg:text-[13px] "
                          htmlFor="full-name"
                        >
                          gmail
                        </label>
                        <input
                          className="border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:mt-2 mx-auto m-auto lg:w-[20rem]"
                          type="text"
                          id="username"
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                      </div>

                      <div className="xs:flex xs:flex-row xs:items-center xs:gap-[3rem]">
                        <label
                          className="xs:relative xs:top-4 block text-green-700 font-bold mb-2 mont-serif relative lg:top-[18px] lg:right-[125px] l:text-[13px] "
                          htmlFor="full-name"
                        >
                          Password
                        </label>
                        <div className="relative">
                          <input
                            className="xs:relative xs:right-[8px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:mt-2 mx-auto m-auto lg:w-[20rem]"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                          <button className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none">
                            {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                          </button>
                        </div>
                      </div>
                      <Link to="/otp-phone">
                        <p
                          className="text-green-700 text-underline cursor-pointer text-end lg:mt-[2px]"
                          style={{ textDecoration: "underline" }}
                        >
                          Forgot password
                        </p>
                      </Link>

                      <div
                        className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[4rem] xs:mt-4"
                        style={{ alignItems: "center" }}
                      >
                        <button
                          type="submit"
                          style={{ borderRadius: "11px", fontSize: "14px" }}
                          className="xs:w-[15rem] mont-serif text-black button-1 border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[86%]"
                        >
                          Login
                        </button>

                        <button
                          onClick={Routememberform}
                          style={{
                            borderRadius: "11px",
                            fontSize: "14px",
                            border: "1px solid",
                          }}
                          className="xs:w-[15rem] border-green-700 mont-serif text-black  border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[86%]"
                        >
                          Sign up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* buttons below  */}
                {/*  */}{" "}
                <p className="relative lg:mt-[38px] top-[12px] text-green-700">
                  or
                </p>
                <div
                  className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[4rem] xs:mt-5"
                  style={{ alignItems: "center" }}
                >
                  <button
                    style={{
                      borderRadius: "11px",
                      fontSize: "14px",
                      border: "1px solid",
                    }}
                    className="xs:w-[15rem] border-green-700 mont-serif text-black  border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[56%]"
                  >
                    Login with facebook
                  </button>

                  <button
                    style={{
                      borderRadius: "11px",
                      fontSize: "14px",
                      border: "1px solid",
                    }}
                    className="xs:w-[15rem] border-green-700 mont-serif lg:w-[56%] text-black  bg-white lg:p-[2px] focus:outline-none hover:bg-teal-100 rounded text-lg border-2 "
                  >
                    Login with Google
                  </button>
                </div>
              </div>

              {/* flex-2  */}
              <div className="sm:w-1/2  px-4 bg-[#00000012] lg:p-[14px] xs:hidden lg:block">
                <div
                  className="flex flex-col justofy-center align-middle "
                  style={{ alignItems: "center" }}
                >
                  <h1 className="text-white text-lg font-semibold lg:mt-20   relative lg:bottom-11 mont-serif">
                    {" "}
                    DTEC RABBIT
                  </h1>

                  <div className="lg:container lg:mx-auto">
                    <img
                      className="rounded-lg shadow-lg mx-auto object-cover lg:h-[15rem] w-[auto]"
                      src={logo}
                      alt=""
                    />
                  </div>
                </div>

                <div
                  className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[8.9rem]"
                  style={{ alignItems: "center" }}
                >
                  <button
                    style={{ borderRadius: "11px", fontSize: "14px" }}
                    className=" lg:w-[278px]  mont-serif text-black bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg "
                  >
                    App store
                  </button>

                  <button
                    style={{ borderRadius: "11px", fontSize: "14px" }}
                    className=" lg:w-[278px]   mont-serif  text-black  bg-white border-0 lg:p-[2px] focus:outline-none hover:bg-teal-100 rounded text-lg drop-shadow-md shadow-lg"
                  >
                    Google play
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
