import React from 'react'
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function Myeventsslider() {
  const carouselData = [
    {
      name: "Office",
      image:
        "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWVldGluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Legacy",
      image:
        "https://images.unsplash.com/photo-1543269865-cbf427effbad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8bWVldGluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Meeting",
      image:
        "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWVldGluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "One to One",
      image:
        "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fG1lZXRpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Buyer",
      image:
        "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWVldGluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Merchant",
      image:
        "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWVldGluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Vendor",
      image:
        "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWVldGluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Meeting",
      image:
        "https://plus.unsplash.com/premium_photo-1661757333258-f9c25fd64546?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG1lZXRpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Gathering",
      image:
        "https://plus.unsplash.com/premium_photo-1661757333258-f9c25fd64546?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG1lZXRpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      name: "Meeting",
      image:
        "https://plus.unsplash.com/premium_photo-1661757333258-f9c25fd64546?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG1lZXRpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    },
  ];
  const scrollLeft = () => {
    document.getElementById("content-a").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("content-a").scrollLeft += 400;
  };
  // 
  const navigate = useNavigate();

  const handleroute = () => {
    navigate(`/upcommingevents`);
  };
  return (
    <>
   <div   className="relative fade-in-left mx-auto">
      {/* <div   className="text-center py-4  text-xl font-bold"></div> */}
      <h1     className="text-2xl text-green-700 mont-serif lg:mt-[30px]  xs:mt-4 xs:ml-4">
 My Events
      </h1>

      <div   className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px] ">
        <button onClick={scrollLeft}   className="lg:w-[1px] relative lg:top-[56px] lg:right-[73.2rem] xxl:right-[64.2rem] lg:h-[4rem] p-2 m-2 rounded-full carousel-bg">
          <FiChevronLeft    className="relative lg:right-[7px] text-white"/>
        </button>
        <button onClick={scrollRight}   className="lg:w-[1px] relative lg:top-[56px] lg:left-[46px] lg:h-[4rem] p-2 m-2 rounded-full carousel-bg">
          <FiChevronRight   className="relative lg:right-[7px] text-white" />
        </button>
      </div>
      <div
  id="content-a"
    className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] lg:-m-4 mt-1"
>
{carouselData.map((item) => (
          <div className="carousel-card lg:ml-[-18px]" key={item.name}>
            <div className="p-4 lg:w-[265px] xxl:w-[227px] xs:w-[14rem] drop-shadow-xl">
              <div className="lg:h-max	 xs:h-max bg-opacity-75  shadow-inner rounded-lg overflow-hidden text-center relative intrest-card   ">
                <img
                  src={item.image}
                  className="lg:mt-[-2px] xs:relative  lg:relative  "
                  alt=""
                />
                <h5 className=" xs:w-[12.2rem]  xs:p-[3px] text-[17px] text-[#005125] mont-serif font-extrabold relative lg:top-[74] lg:p-[3px] text-center text-bg bg-text  lg:w-[30vh]  lg:m-auto  ">
                  <span>{item.name}</span>
                </h5>
              </div>
            </div>
          </div>
        ))}
</div>
    <button onClick={handleroute}   className=" float-right lg:w-[11rem] btn-ad mont-serif text-black lg:mt-[17px] lg:mb-[24px] scale-in-hor-right xs:mr-[1rem]">Create new event</button>
    </div>
    </>
  )
}
